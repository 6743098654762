import React, { useEffect, useState } from 'react';
import { Button } from '@willeder/component-library';
import Modal from 'components/atoms/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import FileListLayout from 'components/organism/FileListLayout';
import Sidebar from 'components/organism/SideBar';
import DragDropModal from 'components/molecules/DragDropModal';
import useSpace from 'hooks/space/useSpace';
import useSpaceFiles from 'hooks/spaceFiles/useSpaceFiles';
import userSpaceStore from 'store/userSpaceStore';
import useModalStore from 'store/useModalStore';
import styles from './SenderSpace.module.scss';
import useNotificationStore from 'store/notificationStore';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from 'utils/helper';

const SenderSpace = () => {
  const { spaceId } = useParams<{ spaceId: string }>();
  const navigate = useNavigate();

  const {
    setCurrentSpaceId,
    currentSpaceId,
    setFilesToUpload,
    spaceFilesToUpload,
    setTempSpace,
    setSpaceReceivers,
    setSpaceSizeData,
    setRecentBulkId,
  } = userSpaceStore((state) => state);
  const { spaceData, error, getSpaceData } = useSpace(
    spaceId !== 'temp-space' ? spaceId : currentSpaceId
  );
  const {
    spaceFiles,
    isLoading,
    addFilesToSpace,
    spaceSizeData,
    getStorageInfo,
  } = useSpaceFiles();
  const { t } = useTranslation();
  const { setOpenModal, closeModal, setModalProps } = useModalStore();
  const isReloadReceiver = useNotificationStore(
    (state) => state.isReloadReceiver
  );
  const setReloadReceiverStatus = useNotificationStore(
    (state) => state.setReloadReceiverStatus
  );
  const [isTempSpace, setIsTempSpace] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [files, setFiles] = useState<File[] | null>(null);
  const [isWarningModal, setWarningModal] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (spaceId === 'temp-space') {
      setCurrentSpaceId('');
      setIsTempSpace(true);
      setShowUploadModal(true);
    } else if (spaceId) setCurrentSpaceId(spaceId);
  }, [spaceId]);

  useEffect(() => {
    if (spaceSizeData?.success) {
      setSpaceSizeData(spaceSizeData.result[0]);
    } else getStorageInfo();
  }, [spaceSizeData]);

  useEffect(() => {
    if (isReloadReceiver) {
      getSpaceData();
      setReloadReceiverStatus(false);
    }
  }, [isReloadReceiver]);

  const onFilesUploadToStorage = async () => {
    setIsUploaded(false);
    if (!currentSpaceId) setTempSpace(true);
    const cancellUpload = () => setFiles(null);
    if (files) {
      setOpenModal('fileUploadModal');
      setModalProps({
        fileList: files,
        isTempSpace: isTempSpace,
        onCancellFileUpload: cancellUpload,
      });
    }
  };

  useEffect(() => {
    if (spaceData?.data?.receiverDetails) {
      setSpaceReceivers(spaceData?.data?.receiverDetails);
    }
    if (error) {
      setErrorText(t(getErrorMessage(error.subStatusCode)));
    }
  }, [setSpaceReceivers, spaceData, error]);

  const addFileToDatabase = async () => {
    try {
      if (spaceFilesToUpload) {
        if (isTempSpace) {
          setOpenModal('createSpace');
          setIsUploaded(true);
          setFilesToUpload(spaceFilesToUpload);
          setIsTempSpace(false);
        } else {
          addFilesToSpace(spaceFilesToUpload)
            .then((response) => {
              if (response.success) {
                if (!isTempSpace) setFilesToUpload(null);
                setIsUploaded(true);
                setModalProps({ fileList: [] });
                setRecentBulkId(spaceFilesToUpload.files[0].bulkId);
                // const delayTime = 2000;
                // setTimeout(() => {
                //   closeModal();
                // }, delayTime);
              }
            })
            .catch((e) => console.log(e));
        }
        setShowUploadModal(false);
        setFiles(null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFiles(null);
      getStorageInfo();
    }
  };

  useEffect(() => {
    if (!isUploaded && spaceFilesToUpload) {
      addFileToDatabase();
    }
  }, [spaceFilesToUpload, isUploaded]);

  useEffect(() => {
    if (errorText) {
      setWarningModal(true);
    }
  }, [errorText]);

  return (
    <div className={styles.wrapper}>
      <Sidebar
        receivers={spaceData?.data.receiverDetails}
        showStatus={!!spaceFiles?.result?.length}
      />
      {isLoading ? (
        <div className={styles.textCenter}>Loading...</div>
      ) : (
        <div className={styles.cover}>
          <h1>
            {spaceData?.data?.spaceName
              ? spaceData?.data?.spaceName
              : spaceData?.data?.alternativeReceiverName || 'Sender Space'}
          </h1>
          <div className={styles.uploadButton}>
            <Button
              className="selectButton_after"
              onClick={() => setShowUploadModal(true)}
            >
              + {t('buttons.upload')}
            </Button>
          </div>
          <FileListLayout spaceFiles={spaceFiles?.result || []} />
        </div>
      )}
      <Modal
        isOpen={showUploadModal}
        onClose={() => !isTempSpace && setShowUploadModal(false)}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <DragDropModal
            files={files}
            setFiles={setFiles}
            onFilesUploadToStorage={onFilesUploadToStorage}
            onCancel={() => {
              if (isTempSpace) navigate('/');
              else setShowUploadModal(false);
              setFiles(null);
            }}
          />
        </div>
      </Modal>
      <Modal isOpen={isWarningModal} onClose={() => {}}>
        <div>
          <p style={{ marginBottom: '20px' }}>{errorText}</p>
          <Button
            className="close"
            disabled={isLoading}
            onClick={() => navigate('/')}
          >
            {t('buttons.close')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SenderSpace;
