import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@willeder/component-library';
import Randomstring from 'randomstring';
import { uploadFileWithPutCommand } from 'services/awsFileUpload';
import { useSpaceFile } from 'hooks/encryption/useSpaceFile';
import userSpaceStore from 'store/userSpaceStore';
import useModalStore from 'store/useModalStore';
import { bytesToSize, truncateFileName } from 'utils/helper';
import styles from './FileUploadModal.module.scss';
import useAlert from 'store/useAlert';
import Loader from 'components/atoms/Loader';
import { useTranslation } from 'react-i18next';

interface FileUploadProps {
  fileList: File[];
  isTempSpace: boolean;
  onCancellFileUpload: () => void;
}

const abortController = new AbortController();

const FileUploadModal: React.FC<FileUploadProps> = ({
  fileList,
  isTempSpace,
  onCancellFileUpload,
}) => {
  const filesRef = useRef<FileWithProgress[]>([]);
  const [combinedProgress, setCombinedProgress] = useState(2);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [uploadStage, setUploadStage] = useState<
    'encrypting' | 'uploading' | 'done'
  >('encrypting');
  const { t } = useTranslation();

  const { closeModal } = useModalStore();
  const { setFilesToUpload } = userSpaceStore((state) => state);
  const { encryptFileData } = useSpaceFile();
  const setAlert = useAlert((state) => state.setAlert);

  const totalSize =
    fileList?.reduce((acc, fileData) => acc + fileData.size, 0) || 0;

  useEffect(() => {
    if (isUploaded && uploadStage === 'done') {
      const timeoutId = setTimeout(() => {
        setAlert('success', 'success');
        closeModal();
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [closeModal, isUploaded, setAlert, uploadStage]);

  useEffect(() => {
    if (fileList && !isUploaded && !isCancelled) {
      const initializedFiles = fileList.map((file) => ({
        file: file,
        size: file.size,
        progress: 0,
        uploaded: false,
        status: 'waiting',
      }));
      filesRef.current = initializedFiles;
      onFilesUploadToStorage(initializedFiles);
    }
  }, [fileList, isUploaded, isCancelled]);

  const updateFileStatus = (index: number, status: string) => {
    const updatedFiles = filesRef.current.map((file, idx) =>
      idx === index ? { ...file, status } : file
    );
    filesRef.current = updatedFiles;
  };

  const onFilesUploadToStorage = async (
    initializedFiles: FileWithProgress[]
  ) => {
    if (!initializedFiles.length) return;

    try {
      const bulkId = Randomstring.generate();
      const filesInfo = [];

      for (
        let currentIndex = 0;
        currentIndex < initializedFiles.length;
        currentIndex++
      ) {
        if (isCancelled) {
          throw new Error('Upload cancelled');
        }
        const fileData = initializedFiles[currentIndex];
        setUploadStage('encrypting');

        const encryptedFile = await encryptFileData(fileData.file);
        if (encryptedFile) {
          const updatedFiles = filesRef.current.map((file, idx) =>
            idx === currentIndex ? { ...file, status: 'encrypted' } : file
          );
          filesRef.current = updatedFiles;

          await new Promise((resolve) => setTimeout(resolve, 500));
          setUploadStage('uploading');

          const filepath = await uploadFileWithPutCommand(
            fileData.file,
            currentIndex,
            encryptedFile,
            totalSize,
            filesRef.current,
            (data: FileWithProgress[]) => {
              filesRef.current = data;
            },
            setCombinedProgress,
            abortController
          );

          const fileInfo: FileDetails = {
            fileName: fileData.file.name,
            filePath: filepath || '',
            fileSize: fileData.file.size,
            bulkId: bulkId,
          };

          filesInfo.push(fileInfo);

          await new Promise((resolve) => setTimeout(resolve, 200));
          setUploadStage('done');
          updateFileStatus(currentIndex, 'done');

          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      }

      const filteredFilesInfo: FileDetails[] = filesInfo.filter(
        (fileInfo: FileDetails | undefined): fileInfo is FileDetails =>
          fileInfo !== undefined
      );

      const preparePayload: FilesUploadPayload = {
        files: filteredFilesInfo,
        encryptedSecretCode: Randomstring.generate(),
      };

      setFilesToUpload(preparePayload);
      setIsUploaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancell = () => {
    setIsCancelled(true);
    abortController.abort();
    setAlert(t('errors.cancelledUpload'), 'info');
    closeModal();
    onCancellFileUpload();
  };

  return (
    <div className={styles.fileModal}>
      <h1 className={styles.title}>{t('common.uploading')}</h1>
      <div className={styles.fileWrapper}>
        <meter
          className={styles.progressBar}
          value={combinedProgress}
          min={0}
          max={100}
        ></meter>
        <div className={styles.uploadInfo}>
          {`${combinedProgress?.toFixed(0)}% ${t('common.uploading')}....`}{' '}
          <Loader />
        </div>
      </div>
      <div className={styles.wrapper}>
        {filesRef.current.map((file, index) => {
          return (
            <div className={styles.fileInfo} key={file.file.name}>
              <p className={styles.fileName}>
                {truncateFileName(file.file.name)}
              </p>
              <span className={styles.size}>
                {bytesToSize(file.file?.size)}
                <div className={styles.statusInfo}>
                  <div style={{ maxWidth: '100px' }}>
                    {file.status === 'waiting' && (
                      <div className={styles.waiting}>
                        {t('common.waiting')}...
                      </div>
                    )}
                    {file.status === 'encrypted' && (
                      <div className={styles.encrypted}>
                        {t('common.encrypted')}
                      </div>
                    )}
                    {file.status === 'done' && (
                      <div className={styles.done}>{t('common.done')}</div>
                    )}
                  </div>
                </div>
              </span>
            </div>
          );
        })}
      </div>
      <div className={styles.buttonWrapper}>
        <Button className="close" disabled={isUploaded} onClick={handleCancell}>
          {t('buttons.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default FileUploadModal;
