import React, { useEffect, useState } from 'react';
import { Button } from '@willeder/component-library';
import useFileOperations from 'hooks/spaceFiles/useFileOperations';
import { bytesToSize } from 'utils/helper';
import { ReactComponent as LeftArrow } from '../../../assets/icons/leftarrow.svg';
import { ReactComponent as DownArrow } from '../../../assets/icons/downarrow.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download_active.svg';
import { ReactComponent as DownloadDeactive } from '../../../assets/icons/download_deactivate.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/ph_link.svg';
import styles from './AccordionTable.module.scss';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../ConfirmModal';
import userSpaceStore from 'store/userSpaceStore';
import useSpaceFiles from 'hooks/spaceFiles/useSpaceFiles';
import tooltip from 'components/atoms/ToolTip';
import { truncateFileName } from 'utils/helper';

const ToolTipLinkIcon = tooltip(LinkIcon, {
  className: {
    container: 'react-tooltip',
    content: 'react-tooltip__content',
  },
});

const TooltipDownloadIcon = tooltip(DownloadIcon, {
  className: {
    container: 'react-tooltip',
    content: 'react-tooltip__content',
  },
});

const TooltipDeleteIcon = tooltip(DeleteIcon, {
  className: {
    container: 'react-tooltip',
    content: 'react-tooltip__content',
  },
});

const AccordionTable: React.FC<AccordionTableProps> = ({
  items,
  selectAllChecked,
  showReceiverAccordion = false,
  header,
  handleSelectAllChange,
}) => {
  const {
    isModalOpen,
    selectedItems,
    setIsModalOpen,
    isDeleteModal,
    setDeleteModal,
    expandedItems,
    handleCheckboxChange,
    toggleExpand,
    setExpandedItems,
    onFilesStatusChange,
    onDownloadFiles,
    onDeleteFiles,
    onShareBulkFiles,
    onUpdateSelectedItems,
  } = useFileOperations(items);

  const { t } = useTranslation();
  const { receiverData, recentBulkId, setRecentBulkId, isTempSpace } =
    userSpaceStore();

  const { spaceFiles } = useSpaceFiles();

  const [fileId, setFileId] = useState({
    id: '',
    status: 'off',
  });

  useEffect(() => {
    if (items) {
      let recentFileIds: string[] = [];
      items.forEach((fileDetails) => {
        const createdAtDate = new Date(fileDetails?.createdAt);
        const activeAtDate = receiverData?.activeAt
          ? new Date(receiverData?.activeAt)
          : new Date();
        if (recentBulkId && fileDetails.bulkId === recentBulkId)
          recentFileIds.push(fileDetails.bulkId);
        if (createdAtDate > activeAtDate && showReceiverAccordion) {
          recentFileIds.push(fileDetails.id);
          if (!expandedItems.includes(fileDetails.bulkId))
            setExpandedItems((prev) => [...prev, fileDetails.bulkId]);
          if (fileDetails.children)
            fileDetails.children.forEach((child) =>
              recentFileIds.push(child.id)
            );
        }
      });
      onUpdateSelectedItems(recentFileIds);
    }

    return () => {
      setRecentBulkId('');
    };
  }, [receiverData, recentBulkId, isTempSpace]);

  useEffect(() => {
    if (recentBulkId && spaceFiles) {
      let recentFileIds = [];
      recentFileIds.push(recentBulkId);

      spaceFiles.result.forEach((fileData) => {
        if (fileData.bulkId === recentBulkId) {
          recentFileIds.push(fileData.fileId);
        }
      });
      onUpdateSelectedItems(recentFileIds);
      if (!expandedItems.includes(recentBulkId)) toggleExpand(recentBulkId);
    }
  }, [recentBulkId, spaceFiles]);

  const renderHeaderCells = () => {
    return header.map((th, index) => (
      <th className={styles.tableHeading} key={index}>
        {t(`${th}`)}
      </th>
    ));
  };

  const renderItems = (items: AccordionItem[], isChild: boolean = false) => {
    const iconCount = showReceiverAccordion ? 1 : isChild ? 1 : 3;

    return items.map((item, index) => {
      // NOTE: activeAt is createdAt (helper.ts: prepareAccordionFileData)
      const createdAtDate = new Date(item?.createdAt);
      const activeAtDate = receiverData?.activeAt
        ? new Date(receiverData?.activeAt)
        : new Date();

      const rowStyle = {
        backgroundColor:
          item.status === 'labels.off' ? '#e5e5e5' : 'transparent',
        fontWeight:
          (createdAtDate > activeAtDate && showReceiverAccordion) ||
          item.bulkId === recentBulkId
            ? 'bold'
            : 'normal',
      };

      return (
        <React.Fragment key={index}>
          <tr style={rowStyle}>
            <td className={styles.tdCheckboxStyle}>
              <input
                className={styles.cursorPointer}
                type="checkbox"
                // disabled={item.status === 'labels.off'}
                checked={selectedItems.includes(item.id)}
                onChange={(e) =>
                  handleCheckboxChange(item, e.target.checked, isChild)
                }
              />
            </td>
            {item.children ? (
              <td className={`${styles.detailsControl} ${styles.tableData}`}>
                <button
                  onClick={() => toggleExpand(item.id)}
                  className={`${styles.cursorPointer} ${styles.bgBorderNone}`}
                >
                  {expandedItems.includes(item.id) ? (
                    <DownArrow />
                  ) : (
                    <LeftArrow />
                  )}
                </button>
                {item.title} ({!isChild && item.children?.length})
              </td>
            ) : (
              <td
                title={item.title}
                className={`${styles.detailsControl} ${styles.tableData} ${isChild ? styles.nested : ''}`}
              >
                {truncateFileName(item.title)}
              </td>
            )}
            <td>{bytesToSize(item.fileSize)}</td>
            <td>{!isChild && item.status && t(item.status)}</td>
            <td>{!isChild && item.activatedAt}</td>
            <td
              className={`${styles.iconWrapperFlex} ${iconCount <= 2 ? styles.flexEnd : styles.spaceBetween}`}
            >
              {!showReceiverAccordion && (
                <>
                  {!isChild && (
                    <React.Fragment>
                      <Button
                        className={
                          item.status === 'labels.off' ? 'turnOn' : 'turnOff'
                        }
                        onClick={() => {
                          setFileId({
                            id: item.id,
                            status:
                              item.status === 'labels.off'
                                ? 'labels.on'
                                : 'labels.off',
                          });
                          setIsModalOpen(true);
                        }}
                      >
                        {item.status === 'labels.off'
                          ? t('buttons.turnOn')
                          : t('buttons.turnOff')}
                      </Button>
                      <ToolTipLinkIcon
                        onClick={() => {
                          if (item.status !== 'labels.off') {
                            onShareBulkFiles(item.bulkId);
                          }
                        }}
                        title={t('labels.copyLink')}
                        style={{
                          pointerEvents:
                            item.status === 'labels.off' ? 'none' : 'auto',
                          opacity: item.status === 'labels.off' ? 0.5 : 1,
                        }}
                      />
                    </React.Fragment>
                  )}
                  <div className={styles.lastChildIconWrapper}>
                    <TooltipDownloadIcon
                      onClick={() => onDownloadFiles(item.id)}
                      title={t('buttons.download')}
                    />
                    <TooltipDeleteIcon
                      onClick={() => {
                        setFileId({
                          id: item.id,
                          status: item.status || '',
                        });
                        setDeleteModal(true);
                      }}
                      title={t('buttons.delete')}
                    />
                  </div>
                </>
              )}
              {showReceiverAccordion && (
                <TooltipDownloadIcon
                  title={t('buttons.download')}
                  onClick={() => onDownloadFiles(item.id)}
                />
              )}
            </td>
          </tr>
          {item.children &&
            expandedItems.includes(item.id) &&
            renderItems(item.children, true)}
        </React.Fragment>
      );
    });
  };

  const isAllChecked = items.every(
    (item) =>
      selectedItems.includes(item.id) ||
      (item.children &&
        item.children.every((child) => selectedItems.includes(child.id)))
  );

  return (
    <div className={styles.tableWrapper}>
      <table cellSpacing={'0px'} className={styles.accordionTable}>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAllChecked && isAllChecked}
                onChange={(e) => {
                  handleSelectAllChange(e.target.checked);
                }}
              />
            </th>
            {renderHeaderCells()}
          </tr>
        </thead>
        <tbody>
          {renderItems(items)}
          <ConfirmModal
            title={''}
            content={
              <p>
                {t('labels.filesStatusChangeConfirmation', {
                  status: t(fileId.status),
                })}
              </p>
            }
            cancel={{
              children: t('buttons.no'),
              onClick: () => setIsModalOpen(false),
            }}
            confirm={{
              children: t('buttons.yes'),
              onClick: () => fileId && onFilesStatusChange(fileId.id),
            }}
            isOpen={isModalOpen}
          />
        </tbody>
      </table>
      <ConfirmModal
        title={t('labels.deleteFiles')}
        content={<p>{t('labels.deleteFileConfirmation')}</p>}
        cancel={{
          children: t('buttons.no'),
          onClick: () => setDeleteModal(false),
        }}
        confirm={{
          children: t('buttons.yes'),
          onClick: () => fileId && onDeleteFiles(fileId.id),
        }}
        isOpen={isDeleteModal}
      />
    </div>
  );
};

export default AccordionTable;
